import React, { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useTranslation } from 'react-i18next';
import { renderTextWithList } from '../../common/helpers/renderText';
import useLastCallback from '../../../hooks/useLastCallback';
import useHistoryBack from '../../../hooks/useHistoryBack';
import {
  LeftColumnContent,
  MiddleColumnContent,
  SettingsScreens,
} from '../../../types';
import { getActions } from '../../../global';
import { LANDING_HOSTNAME, SUPPORT_USER_ID } from '../../../config';
import { Navigation } from 'swiper';

const SLIDES = [
  'secure',
  'social',
  'tik-tok',
  'connecting',
  'ai-space',
  'channel',
  'course',
  'group',
  'channels-future',
];

type OwnProps = {
  currentUserId?: string;
};

const WelcomeEllo: FC<OwnProps> = ({ currentUserId }) => {
  const { t } = useTranslation();

  const { toggleLeftColumn, openChat, openUrl } = getActions();

  const { toggleScreens } = useHistoryBack({});

  const handleClick = useLastCallback((name: string) => {
    switch (name) {
      case 'ai-space':
        toggleScreens({
          leftScreen: LeftColumnContent.Settings,
          middleScreen: MiddleColumnContent.Settings,
          settingScreen: SettingsScreens.aiSpace,
        });
        toggleLeftColumn();
        break;
      case 'channel':
      case 'tik-tok':
        toggleScreens({ leftScreen: LeftColumnContent.NewChannelSelect });
        break;
      case 'group':
        toggleScreens({ leftScreen: LeftColumnContent.NewGroupStep1 });
        break;
      case 'course':
        toggleScreens({ leftScreen: LeftColumnContent.NewCoursSelect });
        break;
      case 'help':
        openChat({ id: SUPPORT_USER_ID });
        break;
      case 'secure':
        openChat({ id: currentUserId, shouldReplaceHistory: true });
        break;
      case 'social':
      case 'channels-future':
        toggleScreens({
          leftScreen: LeftColumnContent.Feed,
          middleScreen: MiddleColumnContent.Feed,
          pageTitle: 'Feed',
          hash: 'feed',
        });
        break;
      case 'connecting':
        toggleScreens({
          leftScreen: LeftColumnContent.GlobalSearch,
        });
        toggleLeftColumn();
    }
  });

  return (
    <div className='welcome-swiper'>
      <h3 className='text-center'>
        Meet Ello, the most secure messenger in the world.
      </h3>
      <Swiper
        loop
        navigation={true}
        centeredSlides={true}
        spaceBetween={8}
        slidesPerView={'auto'}
        modules={[Navigation]}
      >
        {SLIDES.map((name, idx) => (
          <SwiperSlide key={`${name}-${idx}`}>
            <div className='item-wrap'>
              <div
                onClick={() => handleClick(name)}
                className={`item-bg ${name}`}
              >
                <div className='title'>
                  <span>{t(`Welcome.${name}-title`)}</span>
                </div>
              </div>

              <div className='description custom-scroll'>
                {renderTextWithList(
                  t(`Welcome.${name}-content`, {
                    returnObjects: true,
                  })
                )}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default WelcomeEllo;
