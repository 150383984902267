import React, { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../ui/Modal';
import Button from '../ui/Button';

import renderText, { renderTextWithList } from '../common/helpers/renderText';
import { LOCAL_TGS_URLS } from '../common/helpers/animatedAssets';
import AnimatedIcon from '../common/AnimatedIcon';
import NotMoneyImg from '../../assets/images/not_money.png';
import { IPayment } from '../../global/types';
import { getMoneyFormat } from '../../util/convertMoney';
import IconSvg from '../ui/IconSvg';
import AiPurchase from '../../assets/images/congratulations.svg';
import classNames from 'classnames';
import Skeleton from '../ui/Skeleton';

export type OwnProps = {
  isOpen: boolean;
  onClose: () => void;
  onCloseAnimationEnd?: () => void;
  payment?: IPayment;
  title?: string;
  text?: object;
  closeLabel?: string;
  confirmLabel?: string;
  confirmIsDestructive?: boolean;
  header?: string;
  transactionType?:
    | 'deposit'
    | 'withdrawal'
    | 'paidCourse'
    | 'paidSubscription'
    | 'aiPurchased';
  confirmHandler?: () => void;
};

const ConfirmPayModal: FC<OwnProps> = ({
  isOpen,
  onClose,
  payment,
  title,
  text,
  closeLabel = 'Ok',
  confirmLabel,
  confirmIsDestructive,
  transactionType,
  header,
  confirmHandler,
}) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const { status } = payment || {};
  function renderContent() {
    switch (status) {
      case 'canceled':
        return (
          <>
            <div className='AvatarEditable'>
              <AnimatedIcon tgsUrl={LOCAL_TGS_URLS.FailDeposit} size={250} />
            </div>
          </>
        );
      case 'subscribed':
        return (
          <>
            <div className='AvatarEditable'>
              <AnimatedIcon
                tgsUrl={LOCAL_TGS_URLS.SuccessSubscribed}
                size={250}
              />
            </div>
          </>
        );
      case 'completed':
        return (
          <>
            <div className='AvatarEditable'>
              <AnimatedIcon
                tgsUrl={LOCAL_TGS_URLS.SuccessfulDeposit}
                size={250}
              />
            </div>
          </>
        );

      case 'not-money':
        return (
          <>
            <div className='AvatarEditable'>
              {transactionType === 'paidCourse' ? (
                <AnimatedIcon tgsUrl={LOCAL_TGS_URLS.FailDeposit} size={250} />
              ) : (
                <img src={NotMoneyImg} alt='not money' loading='lazy' />
              )}
            </div>
          </>
        );
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      className='confirm payment-confirm'
      onClose={onClose}
      centered
      header={header}
      hasCloseButton
    >
      <div className='modal-content'>
        {renderContent()}
        {title && <h2>{title}</h2>}
        <div className={classNames('mb-4', { amount: status === 'completed' })}>
          {!text &&
            renderText(
              t(
                `Wallet.${
                  status === 'subscribed'
                    ? transactionType === 'paidSubscription'
                      ? 'PaidChannelSuccessful'
                      : 'PaidCourseSuccessful'
                    : status === 'completed'
                    ? `${
                        transactionType === 'deposit' ? 'Deposit' : 'Withdraw'
                      }Successful`
                    : `${
                        transactionType === 'deposit' ? 'Deposit' : 'Withdraw'
                      }Unsuccessful`
                }`
              ),
              ['simple_markdown', 'br']
            )}
          {!text && status === 'completed' && (
            <>
              <IconSvg name='dollar' w='15' h='15' />
              {getMoneyFormat(payment?.amount, 2, 2)}
            </>
          )}
          {text && renderTextWithList(text)}
        </div>

        <div className='dialog-buttons mt-2' ref={containerRef}>
          {confirmHandler && (
            <Button
              size='smaller'
              onClick={confirmHandler}
              color={confirmIsDestructive ? 'danger' : 'primary'}
            >
              {confirmLabel}
            </Button>
          )}
          <Button outline size='smaller' onClick={onClose}>
            {t(closeLabel)}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmPayModal;
