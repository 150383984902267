import React, { FC, memo, useCallback, useMemo } from 'react';
import { getActions } from '../../global';

import { copyTextToClipboard } from '../../util/clipboard';

import useAppLayout from '../../hooks/useAppLayout';

import Button from '../ui/Button';

import { useTranslation } from 'react-i18next';
import IconSvg from '../ui/IconSvg';
import ListItem from '../ui/ListItem';
import QRCodeStyling from '../../lib/qr-code-styling';

import useLastCallback from '../../hooks/useLastCallback';

type OwnProps = {
  inviteLink: string;
  onRevoke?: VoidFunction;
  isDisabled?: boolean;
  qrCodeStyling?: QRCodeStyling;
};

const InviteLink: FC<OwnProps> = ({
  inviteLink,
  onRevoke,
  isDisabled,
  qrCodeStyling,
}) => {
  const { t } = useTranslation();
  const { showNotification, openChatWithDraft } = getActions();

  const { isMobile } = useAppLayout();

  const copyLink = useCallback((link: string) => {
    copyTextToClipboard(link);
    showNotification({
      message: t('Link.Copied'),
    });
  }, []);

  const onDownloadClick = useLastCallback(() => {
    if (qrCodeStyling) {
      qrCodeStyling.download();
    }
  });

  const handleCopyPrimaryClicked = useCallback(() => {
    copyLink(inviteLink);
  }, [copyLink, inviteLink]);

  const handleShare = useCallback(() => {
    openChatWithDraft({ text: inviteLink });
  }, [inviteLink]);

  const actionsArray = useMemo(() => {
    const arr = [
      { title: t('Invitation.GetQr'), icon: 'qr', handler: onDownloadClick },
    ];
    if (onRevoke)
      arr.push({
        title: t('Link.Remove'),
        icon: 'delete',
        handler: onRevoke,
      });
    return arr;
  }, [onRevoke]);

  return (
    <div className='invite-wrap'>
      <ListItem
        className='text-trigger smaller mb-3'
        ripple
        secondaryIcon='filled'
        contextActions={actionsArray}
      >
        <div className='contact-info'>{inviteLink}</div>
      </ListItem>
      <div className='button-group'>
        <Button
          size='smaller'
          className='mb-3'
          onClick={handleCopyPrimaryClicked}
          disabled={isDisabled}
        >
          <IconSvg name='copy' /> {t('Copy.Copy')}
        </Button>
        <Button
          size='smaller'
          className='mb-3'
          onClick={handleShare}
          disabled={isDisabled}
        >
          <IconSvg name='forward' /> {t('Share')}
        </Button>
      </div>
    </div>
  );
};

export default memo(InviteLink);
